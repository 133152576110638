// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-contact-js": () => import("./../src/pages/contact.js" /* webpackChunkName: "component---src-pages-contact-js" */),
  "component---src-pages-goudsmit-js": () => import("./../src/pages/goudsmit.js" /* webpackChunkName: "component---src-pages-goudsmit-js" */),
  "component---src-pages-grafisch-js": () => import("./../src/pages/grafisch.js" /* webpackChunkName: "component---src-pages-grafisch-js" */),
  "component---src-pages-index-js": () => import("./../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-pages-schilderijen-js": () => import("./../src/pages/schilderijen.js" /* webpackChunkName: "component---src-pages-schilderijen-js" */),
  "component---src-templates-blog-post-js": () => import("./../src/templates/blog-post.js" /* webpackChunkName: "component---src-templates-blog-post-js" */)
}

